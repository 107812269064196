$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-navbutton").click(function () {
        $(".js-navbutton, .js-nav, .js-navoverlay").toggleClass("active");
        $(".js-header-global").toggleClass("navOpen");
        $("body").toggleClass("freeze");
    });

    // open submenu in navmobile
    $(".js-navmobile-button").click(function() {
        if($(this).hasClass("active")) {
            $(this).removeClass("active").parent("div").siblings("ul").slideUp();
        } else {
            $(".js-nav ul ul").slideUp();
            $(".js-navmobile-button").removeClass("active");
            $(this).toggleClass("active").parent("div").siblings("ul").slideToggle();
        }
    })

    // trigger click on submenu
    $(".js-nav li.active > div > svg").trigger("click");

    





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTop
    // *
    // *
    $(window).on("scroll", function () {
        if ($(window).scrollTop() > 100) {
            $(".js-scrolltop").addClass("active");
        } else {
            $(".js-scrolltop").removeClass("active");
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderHero
    // *
    // *
    var $heroImage = $(".js-sliderhero");

    if ($heroImage.length) {
        $heroImage.each(function () {
            $(this).not('.slick-initialized').slick({
                slidesToShow: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
                infinite: true,
                prevArrow: $(this).parents(".js-sliderhero-wrapper").find(".js-sliderhero-prev"),
                nextArrow: $(this).parents(".js-sliderhero-wrapper").find(".js-sliderhero-next"),
            });
        });
    }
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cardCarousel
    // *
    // *
    var $cardCarousel = $(".js-cardCarousel");

    if ($cardCarousel.length) {
        $cardCarousel.each(function () {
            $(this).not('.slick-initialized').slick({
                slidesToShow: 3,
                dots: false,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
                infinite: true,
                prevArrow: $(this).parents(".js-cardCarousel-wrapper").find(".js-cardCarousel-prev"),
                nextArrow: $(this).parents(".js-cardCarousel-wrapper").find(".js-cardCarousel-next"),
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 1,
                            dots: true,
                            arrows: false,
                        }
                    },
                ]
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * sliderIntro
    // *
    // *
    var $sliderIntro = $(".js-sliderintro");

    if ($sliderIntro.length) {
        $sliderIntro.each(function () {
            var $sliderImages = $(this).find(".js-sliderintro-images"),
                $sliderContent = $(this).find(".js-sliderintro-content");

            $sliderImages.not('.slick-initialized').slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: true,
                autoplay: false,
                speed: 1000,
                prevArrow: $(this).find(".js-sliderintro-nav-prev"),
                nextArrow: $(this).find(".js-sliderintro-nav-next"),
                appendDots: $(this).find(".js-sliderintro-dots"),
                asNavFor: $sliderContent
            });

            $sliderContent.not('.slick-initialized').slick({
                fade: true,
                dots: false,
                infinite: true,
                arrows: false,
                autoplay: false,
                speed: 1000,
                asNavFor: $sliderImages
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * sliderContent
    // *
    // *
    var $sliderContent = $(".js-slidercontent");


    if ($sliderContent.length) {
        $sliderContent.each(function () {
            var $sliderImages = $(this).find(".js-slidercontent-images"),
                $sliderContent = $(this).find(".js-slidercontent-content");

            $sliderImages.not('.slick-initialized').slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: true,
                autoplay: false,
                speed: 1000,
                prevArrow: $(this).find(".js-slidercontent-nav-prev"),
                nextArrow: $(this).find(".js-slidercontent-nav-next"),
                appendDots: $(this).find(".js-slidercontent-dots"),
                asNavFor: $sliderContent
            });

            $sliderContent.not('.slick-initialized').slick({
                fade: true,
                dots: false,
                infinite: true,
                arrows: false,
                autoplay: false,
                speed: 1000,
                asNavFor: $sliderImages     
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * slider-text
    // *
    // *
    var $sliderText = $(".js-slider-text");

    if ($sliderText.length) {
        $sliderText.each(function () {

            $(this).slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: true,
                autoplay: false,
                speed: 1000,
                prevArrow: $(this).parents(".js-slider-text-wrapper").find(".js-slider-text-nav-prev"),
                nextArrow: $(this).parents(".js-slider-text-wrapper").find(".js-slider-text-nav-next"),

            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *
    // * slider-image-text
    // *
    // *
    var $sliderImageText = $(".js-slider-image-text");

    if ($sliderImageText.length) {
        $sliderImageText.each(function () {

            $(this).not('.slick-initialized').slick({
                fade: false,
                dots: true,
                infinite: true,
                arrows: false,
                autoplay: false,
                speed: 1000,

            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *

    $(".js-accordion-button").click(function () {
        $(this).toggleClass("active").siblings().slideToggle().parent().siblings().find(".js-accordion-button").removeClass("active").siblings().slideUp();

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header-buttons
    // *
    // *
    $(".js-search-button").click(function () {
        $(".js-phone-button").removeClass("active");
        $(this).toggleClass("active").sibling().slideToggle();
    });
    $(".js-phone-button").click(function () {
        $(".js-search-button").removeClass("active");
        $(this).toggleClass("active");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-popup
    // *
    // *
    $(".js-popup-button").click(function () {
        $(this).addClass("hide").parents(".js-sliderintro").find(".js-popup").addClass("show");
    });
    $(".js-popup-button-close").click(function () {
        $(this).parents(".js-popup").removeClass("show").parents(".js-sliderintro").find(".js-popup-button").removeClass("hide");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * text-more
    // *
    // *
    $(".js-text-more-button").click(function () {
        console.log("hallo");
        $(this).toggleClass("active").siblings(".js-text-more").slideToggle();
    });

    



});




// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 100) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});
